@import '../../../../node_modules/@ww-digital/web-palette-react/dist/styles/base.scss';

.container {
  margin-bottom: 30px;
  margin-top: 30px;

  @include breakpoint(small) {
    margin: 40px 120px;
  }
}
