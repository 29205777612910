.wrapper {
  min-width: 300px;
}

.ad {
  text-align: center;

  // This is needed so the region collapses when there is no ad served
  [data-google-query-id] {
    margin-bottom: 30px;
  }
}
