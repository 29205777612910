@import '/node_modules/@ww-digital/web-palette-react/dist/styles/base.scss';

$color--another-gray: #d9dde1;
$color--730--warm-gray-oyster: #8e9192;

@function if-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin link($important: false) {
  $color: $color--ww110 if-important($important);
  font-style: normal if-important($important);
  font-weight: 500 if-important($important);
  font-size: 16px if-important($important);
  line-height: 24px if-important($important);
  color: $color if-important($important);
  border: 2px solid transparent if-important($important);
  border-radius: 30px if-important($important);
  padding: 4px if-important($important);

  &:hover {
    color: $color--ww100 if-important($important);
  }

  &:active,
  &:focus {
    border: 2px solid $color--ww100 if-important($important);
  }
}

@mixin link-inline($important: false) {
  color: $color--ww110 if-important($important);
  font-weight: 500 if-important($important);
  border: 2px solid transparent if-important($important);
  border-radius: 30px if-important($important);
  padding: 4px if-important($important);
  cursor: pointer if-important($important);
  text-decoration: none if-important($important);

  &:hover {
    color: $color--ww100 if-important($important);
  }

  &:active,
  &:focus {
    border: 2px solid $color--ww100 if-important($important);
  }
}

@mixin body3-reg($important: false) {
  font-size: 14px if-important($important);
  line-height: 20px if-important($important);

  @include breakpoint(medium) {
    font-size: 16px if-important($important);
    line-height: 24px if-important($important);
  }
}

@mixin subhead3($important: false) {
  font-size: 16px if-important($important);
  line-height: 24px if-important($important);

  @include breakpoint(medium) {
    font-size: 18px if-important($important);
    line-height: 28px if-important($important);
  }
}

@mixin subhead4($important: false) {
  color: $color--black if-important($important);
  font-size: 14px if-important($important);
  line-height: 20px if-important($important);
  font-weight: 500 if-important($important);

  @include breakpoint(medium) {
    font-size: 16px if-important($important);
    line-height: 24px if-important($important);
  }
}

@mixin subhead5($important: false) {
  font-size: 12px if-important($important);
  line-height: 16px if-important($important);

  @include breakpoint(medium) {
    font-size: 14px if-important($important);
    line-height: 20px if-important($important);
  }
}

@mixin body2l-reg($important: false) {
  font-size: 16px if-important($important);
  line-height: 24px if-important($important);

  @include breakpoint(medium) {
    font-size: 18px if-important($important);
    line-height: 28px if-important($important);
  }
}

@mixin cookie-modal-link {
  @include link($important: true);
  @include subhead3($important: true);
  text-decoration: none !important;
  outline: none !important;
  display: inline-block !important;
}

@mixin cookie-modal-link-inline {
  @include link-inline($important: true);
  text-decoration: none !important;
  outline: none !important;
  margin: 0 !important;
}

@mixin primary-button($important: false) {
  border-radius: 30px if-important($important);
  display: inline-block if-important($important);
  position: relative if-important($important);
  padding: 0 20px if-important($important);
  height: 56px if-important($important);
  border: 2px solid transparent if-important($important);
  background-color: $color--ww110 if-important($important);
  color: $color--white if-important($important);
  font-style: normal if-important($important);
  font-size: 16px if-important($important);
  line-height: 120% if-important($important);
  font-weight: 500 if-important($important);
  cursor: pointer if-important($important);

  &.fixed {
    width: 100% if-important($important);
    max-width: 300px if-important($important);
  }

  &::before {
    $outline-distance: 2px;
    border: 2px solid transparent if-important($important);
    border-radius: 30px if-important($important);
    bottom: $outline-distance if-important($important);
    content: '' if-important($important);
    left: $outline-distance if-important($important);
    position: absolute if-important($important);
    right: $outline-distance if-important($important);
    top: $outline-distance if-important($important);
  }

  &:focus,
  &:active {
    outline: none if-important($important);
    border-color: $color--ww100 if-important($important);
  }

  &:hover {
    background-color: $color--ww100 if-important($important);
    border-color: $color--ww100 if-important($important);
  }

  &.disabled {
    $disabled: $color--ww530 if-important($important);
    background-color: $disabled if-important($important);
    color: $color--ww560 if-important($important);
    border: 0 if-important($important);

    &::after {
      display: none if-important($important);
    }
  }
}

@mixin primary-button-responsive($important: false) {
  height: 48px if-important($important);
  font-size: 16px if-important($important);
  line-height: 16px if-important($important);
  padding: 0 20px if-important($important);
  max-width: 100% if-important($important);

  @include breakpoint(medium) {
    height: 56px if-important($important);
    font-size: 18px if-important($important);
    line-height: 18px if-important($important);
    max-width: max-content if-important($important);
  }
}

@mixin secondary-button-responsive($important: false) {
  @include primary-button-responsive($important);
}

@mixin secondary-button($important: false) {
  @include primary-button($important: $important);

  border: 2px solid $color--ww730 if-important($important);
  color: $color--ww100 if-important($important);
  background-color: transparent if-important($important);

  &:focus,
  &:active {
    border-color: $color--ww730 if-important($important);

    &::before {
      border-color: $color--ww100 if-important($important);
    }
  }

  &:hover {
    background-color: $color--ww730 if-important($important);
    border-color: $color--ww530 if-important($important);

    &::before {
      border-color: transparent if-important($important);
    }
  }
}
