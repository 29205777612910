/* stylelint-disable */

@import '/node_modules/@ww-digital/web-palette-react/dist/styles/base.scss';
@import './helper.scss';

body:not(.disable-onetrust-vs) {
  // First modal
  #onetrust-banner-sdk {
    box-shadow: none !important;
    background-color: $color--white !important;
    max-width: 800px !important;

    @include breakpoint(medium) {
      width: 800px !important;
    }
  }

  .ot-pc-header {
    border-bottom: 1px solid $color--another-gray !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 104px !important;
  }

  .ot-pc-logo {
    width: 40px !important;
    height: 40px !important;
    position: absolute !important;
    top: 36px !important;
    left: 35px !important;
  }

  .ot-pc-footer {
    border-top: 1px solid $color--another-gray !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    background-color: $color--white !important;
    z-index: 1 !important;
  }

  #onetrust-banner-sdk #onetrust-policy {
    margin-top: 40px !important;
  }

  #onetrust-policy-title {
    @include subhead4($important: true);
    padding: 0 20px 10px 20px !important;
    width: 100% !important;
    box-sizing: border-box !important;

    @include breakpoint(medium) {
      padding: 0 40px 10px 40px !important;
    }
  }

  #onetrust-policy-text {
    @include body2l-reg($important: true);
    color: $color--ww560 !important;
    padding: 0 20px !important;
    margin: 0 !important;
    float: none !important;
    box-sizing: border-box !important;
    width: 100% !important;

    @include breakpoint(medium) {
      padding: 0 40px !important;
    }

    a {
      @include cookie-modal-link-inline;
    }
  }

  #onetrust-banner-sdk .ot-link-btn,
  #ot-pc-content .ot-link-btn {
    @include cookie-modal-link;
    color: $color--ww110 !important;
    opacity: 1 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin: 5px 0 0 40px !important;

    @include breakpoint(medium) {
      font-size: 16px !important;
      line-height: 24px !important;
      margin-top: 20px !important;
    }
  }

  #onetrust-banner-sdk .ot-link-btn {
    margin-left: 20px !important;

    @include breakpoint(medium) {
      margin-left: 40px !important;
    }
  }

  #onetrust-button-group-parent {
    float: none !important;
    padding: 0 !important;
  }

  .cookie-setting-link,
  #onetrust-accept-btn-handler {
    margin-bottom: 0 !important;
  }

  #onetrust-accept-btn-handler {
    order: 0 !important;

    @include breakpoint(medium) {
      order: 1 !important;
    }
  }

  #onetrust-reject-all-handler {
    margin: 10px 0 0 0 !important;
    order: 1 !important;

    @include breakpoint(medium) {
      margin: 0 10px 0 0 !important;
      order: 0 !important;
    }
  }

  #onetrust-accept-btn-handler,
  #onetrust-reject-all-handler {
    @include primary-button($important: true);
    @include primary-button-responsive($important: true);
    display: block !important;
  }

  #onetrust-pc-btn-handler {
    @include secondary-button($important: true);
    @include secondary-button-responsive($important: true);
    margin: 10px 0 0 0 !important;
    text-align: center !important;

    @include breakpoint(medium) {
      order: 0 !important;
      margin: 0 10px 0 0 !important;
    }
  }

  #onetrust-accept-btn-handler,
  #onetrust-reject-all-handler,
  #onetrust-pc-btn-handler,
  #accept-recommended-btn-handler,
  .ot-pc-refuse-all-handler,
  .save-preference-btn-handler {
    @include subhead3($important: true);
    width: 100% !important;
    min-width: 200px !important;
    text-decoration: none !important;
    letter-spacing: normal !important;

    &:focus,
    &:hover {
      opacity: 1 !important;
    }
  }

  .banner-actions-container {
    text-align: center !important;
    float: none !important;
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    max-width: 100% !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    box-sizing: border-box !important;

    @include breakpoint(medium) {
      order: 1 !important;
      flex-direction: row !important;
      width: auto !important;
      margin-top: 0 !important;
    }
  }

  #onetrust-button-group {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    padding: 20px 20px 40px !important;
    box-sizing: border-box !important;
    align-items: center !important;

    @include breakpoint(medium) {
      padding: 20px 40px 40px !important;
      flex-direction: row !important;
    }
  }

  // Cookie preferences modal
  #onetrust-pc-sdk {
    box-shadow: none !important;
  }

  #close-pc-btn-handler {
    background-image: url('./imgs/close.svg') !important;
    background-size: 18px !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 !important;
    top: 40px !important;
    right: 20px !important;

    @include breakpoint(medium) {
      right: 40px !important;
    }

    &:hover,
    &:focus {
      opacity: 1 !important;
      border: 1px solid #181877 !important;
    }

    &:focus {
      outline: none !important;

      &:after {
        height: 1px !important;
      }
    }
  }

  #ot-pc-content {
    margin: 0 !important;
    padding: 15px 20px 70px 20px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    top: 105px !important;

    @include breakpoint(medium) {
      padding: 20px 40px 70px 40px !important;
    }
  }

  #ot-pc-title {
    @include subhead4($important: true);
    margin: 0 !important;
    float: none !important;
  }

  #ot-pc-desc {
    @include body3-reg($important: true);
    color: $color--ww560 !important;
    margin: 25px 0 0 0 !important;

    a.privacy-notice-link {
      @include cookie-modal-link;
      color: $color--ww110 !important;
      margin: 5px 0 0 !important;
    }

    a:not(.privacy-notice-link) {
      @include cookie-modal-link-inline;
    }
  }

  #accept-recommended-btn-handler {
    @include primary-button($important: true);
    @include primary-button-responsive($important: true);
    margin: 25px 0 0 !important;
  }

  #ot-category-title {
    @include subhead4($important: true);
    margin: 25px 0 0 !important;
    padding: 0 !important;
  }

  #onetrust-pc-sdk .ot-cat-grp {
    margin: 0 !important;
  }

  .ot-cat-item {
    border: none !important;
    border-bottom: 1px solid $color--ww720 !important;

    > button:focus {
      outline: none !important;
    }
  }

  .ot-cat-header {
    @include subhead4($important: true);
    margin-left: 0 !important;
  }

  #ot-pc-content .ot-plus-minus > span {
    display: none !important;
  }

  #ot-pc-content .ot-plus-minus {
    width: 16px !important;
    height: 16px !important;
    background-image: url('./imgs/plus.svg') !important;
    margin-top: -4px !important;
    margin-left: 17px !important;
    margin-right: 17px !important;

    .ot-hide-acc & {
      background-image: none !important;
    }
  }

  #onetrust-pc-sdk
    #ot-pc-content
    button[aria-expanded='true']
    ~ .ot-acc-hdr
    .ot-plus-minus {
    background-image: url('./imgs/minus.svg') !important;
  }

  .ot-always-active {
    @include subhead4($important: true);
    color: $color--ww100 !important;
    font-weight: 600 !important;
    margin-right: 17px !important;
  }

  #ot-pc-content .ot-tgl {
    height: 25px !important;
    margin-right: 17px !important;
  }

  #onetrust-pc-sdk #ot-pc-content .ot-switch-nob {
    border: none !important;
    background-color: $color--730--warm-gray-oyster !important;
  }

  #onetrust-pc-sdk #ot-pc-content .ot-switch-nob:before {
    background-color: $color--white !important;
    left: 3px !important;
    bottom: 2px !important;
  }

  #onetrust-pc-sdk
    #ot-pc-content
    .ot-tgl
    input:checked
    + .ot-switch
    .ot-switch-nob:before {
    background-image: url('./imgs/toggle_active.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 21px !important;
    border-color: $color--ww100 !important;
    background-color: $color--ww100 !important;
    left: 2px !important;
  }

  #onetrust-pc-sdk
    #ot-pc-content
    .ot-tgl
    input:checked
    + .ot-switch
    .ot-switch-nob {
    border: none !important;
    background-color: $color--ww100 !important;
  }

  #ot-pc-content .ot-acc-hdr {
    padding: 16px 0 15px !important;
  }

  #ot-pc-content .ot-acc-txt {
    background-color: $color--white !important;
  }

  .ot-category-desc {
    @include body3-reg($important: true);
    margin: 0 0 10px !important;
    padding: 0 16px !important;
    color: $color--ww560 !important;
  }

  .ot-hlst-cntr {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  #ot-pc-content .category-host-list-handler {
    @include cookie-modal-link;
    @include subhead5($important: true);
    color: $color--ww110 !important;
    margin: 12px 0 10px 18px !important ;
  }

  .ot-pc-refuse-all-handler {
    @include secondary-button($important: true);
    @include secondary-button-responsive($important: true);
    display: block !important;
    margin: 0 !important;

    @include breakpoint(medium) {
      margin: 0 10px 0 0 !important;
    }
  }

  .save-preference-btn-handler {
    @include primary-button($important: true);
    @include primary-button-responsive($important: true);
    margin: 10px 0 0 !important;
    display: block !important;

    @include breakpoint(medium) {
      margin: 0 !important;
    }
  }

  .ot-pc-footer .ot-btn-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    box-sizing: border-box !important;
    align-items: center !important;
    width: auto !important;
    margin: 16px 0 0 0 !important;
    padding: 0 20px 20px !important;

    @include breakpoint(medium) {
      margin: 20px 0 0 0 !important;
      flex-direction: row !important;
      padding: 0 40px 40px !important;
    }
  }

  // vendor list page
  #ot-pc-lst {
    top: 105px !important;
  }

  #ot-host-lst {
    padding-bottom: 60px !important;
  }
}

/* stylelint-enable */
